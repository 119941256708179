/* General resets */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f6f7;
  color: #333;
  line-height: 1.6;
}

/* Utility classes for spacing */
.mt-1 { margin-top: 1rem; }
.mb-1 { margin-bottom: 1rem; }
.my-1 { margin: 1rem 0; }

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 2rem;
}

/* Navigation styles */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 1rem;
}

nav .logo {
  font-size: 1.5rem;
}

/* Header styles */
header {
  text-align: center;
  padding: 2rem;
  background: #e9eff1;
}

header h1 {
  margin-bottom: 1rem;
}

/* Form styles */
/* form {
  background: #fff;
  padding: 2rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
} */

form label {
  display: block;
  margin-bottom: .5rem;
} 
/* Center the form on the page */
form {
  width: 100%;
  max-width: 500px; /* or whatever width you prefer */
  margin: 2rem auto; /* vertical margins can be whatever you prefer */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style input fields */
input[type="text"] {
  width: 100%; /* makes input take full width of form */
  padding: 10px; /* adjust padding as needed */
  margin: 0.5rem 0; /* adds space between input fields */
  border: 1px solid #ccc; /* border color can be changed as needed */
  border-radius: 4px; /* rounded corners */
}

/* Style the submit button */
input[type="submit"] {
  padding: 10px 20px;
  margin-top: 1rem; /* add space above the button */
  cursor: pointer; /* changes cursor to indicate button */
  border: none;
  border-radius: 4px;
  background-color: #007bff; /* Bootstrap primary button color */
  color: white;
}

form input[type='submit']:hover {
  background: #38bdef;
}


/* form input[type='text'],
form input[type='submit'] {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

form input[type='submit'] {
  background: #5cb85c;
  color: white;
  border: 0;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
} */


/* Results section styles */
section {
  background: #fff;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  /* Added styles for centering */
  display: flex;
  flex-direction: column;
  align-items: center;
}

section h2 {
  color: #333;
}

section p {
  color: #666;
  margin-bottom: 0.5rem;
}

section ul {
  list-style: none;
  line-height: 2;
}

section ul li {
  background: #e9eff1;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
}

/* Footer styles */
footer {
  text-align: center;
  padding: 1rem;
  background: #e9eff1;
  position: absolute;
  bottom: 0;
  width: 100%;
}
